<template>
  <AuditLogTimeline :logs="results" />
</template>

<script>
import { computed, toRef } from "vue-demi";
import { useFind, useGet } from "feathers-pinia";
import { useWorkcenters } from "../store/workcenters.pinia";
import { useAuditLog } from "../store/auditLog.pinia";
import AuditLogTimeline from "./AuditLogTimeline.vue";

export default {
  name: "WorkcenterAuditLog",
  components: {
    AuditLogTimeline,
  },
  props: {
    workcenterId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const workcentersStore = useWorkcenters();
    const auditLogStore = useAuditLog();

    const id = toRef(props, "workcenterId");

    const { item: workcenter } = useGet({
      model: workcentersStore.Model,
      id: id,
    });

    const auditLogParams = computed(() => {
      return {
        query: {
          entity_type: "workcenters",
          entity_id: id.value,
          $limit: 1000,
          $sort: { created: -1 },
        },
      };
    });

    const { items: auditLogs } = useFind({
      model: auditLogStore.Model,
      params: auditLogParams,
    });

    const results = computed(() => {
      return [
        ...(auditLogs.value ?? []),
        {
          id: "created",
          created: workcenter.value?.created,
          field_type: "string",
          new_value: "Created",
          single_line: true,
          created_by_id: workcenter.value?.created_by_id,
        },
      ];
    });

    return {
      workcenter,
      auditLogs,
      results,
    };
  },
};
</script>
